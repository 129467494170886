import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      // console.log('data',data) 
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  title: string;
  message: string;
  message1?: string;
  message2?: string;
}