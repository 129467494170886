import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main/main.component';
import { LayoutModule } from '@angular/cdk/layout';
import { 
  MatToolbarModule, 
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatListModule, 
  MatTableModule, 
  MatPaginatorModule, 
  MatSortModule, 
  MatInputModule, MatSelectModule, MatRadioModule, MatCardModule, MatDatepickerModule, MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonToggleModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatMenuModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatRippleModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatStepperModule, MatTabsModule, MatTooltipModule, MatTreeModule
} from '@angular/material';

import { HomeComponent } from './home/home.component';
import { CustomersComponent } from './customers/customers.component';
import { ArtistsComponent } from './artists/artists.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS }    from '@angular/common/http';
import { InquiriesComponent } from './inquiries/inquiries.component';
import { CompletedProjectsComponent } from './completed-projects/completed-projects.component';
import { ProjectsComponent } from './shared/projects/projects.component';
import { CrudProjectComponent } from './shared/crud-project/crud-project.component';
import { DateListComponent } from './shared/crud-project/date-list/date-list.component';
import { CrudCustomerComponent } from './customers/crud-customer/crud-customer.component';
import { CrudArtistComponent } from './artists/crud-artist/crud-artist.component';
import { LoginComponent } from './login/login.component';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog.component';
import { AssignmentTableComponent } from './shared/crud-project/assignment-table/assignment-table.component';
import { ArtistListComponent } from './shared/crud-project/artist-list/artist-list.component';
import { CustomerAssignmentTableComponent } from './shared/crud-project/customer-assignment-table/customer-assignment-table.component';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import { ArtistsInvoicesComponent } from './artists-invoices/artists-invoices.component';
import { CustomersInvoicesComponent } from './customers-invoices/customers-invoices.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ProjectsComponent,
    HomeComponent,
    CustomersComponent,
    ArtistsComponent,
    CrudProjectComponent,
    DateListComponent,
    InquiriesComponent,
    CompletedProjectsComponent,
    CrudCustomerComponent,
    CrudArtistComponent,
    LoginComponent,
    MessageDialogComponent,
    AssignmentTableComponent,
    ArtistListComponent,
    CustomerAssignmentTableComponent,
    ArtistsInvoicesComponent,
    CustomersInvoicesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    // ToDo Remove not needed modules
    MatAutocompleteModule,
    //MatBadgeModule,
    //MatBottomSheetModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    //MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    //MatExpansionModule,
    //MatGridListModule,
    MatMenuModule,
    //MatNativeDateModule,
    //MatProgressBarModule,
    //MatProgressSpinnerModule,
    MatRippleModule,
    //MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    //MatStepperModule,
    MatTableModule,
    //MatTabsModule,
    MatTooltipModule,
    //MatTreeModule,

    HttpClientModule,
    FormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent],
  entryComponents: [MessageDialogComponent]
})
export class AppModule { }
