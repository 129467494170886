import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { Artist } from '../models/artist';
import { ProjectArtist } from '../models/project-artist';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {
  baseUrl: string = '/api/artists';

  constructor(private http: HttpClient) { }

  getArtists(): Observable<Artist[]> {
    return this.http.get<Artist[]>(this.baseUrl);
  }

  getArtistById(id: number): Observable<Artist> {
    return this.http.get<Artist>(this.baseUrl + '/' + id);
  }

  createArtist(artist: Artist): Observable<Object> {
    return this.http.post(this.baseUrl, artist);
  }

  updateArtist(id: number, artist: Artist): Observable<Object> {
    return this.http.put(this.baseUrl + '/' + id, artist);
  }

  deleteArtist(id: number): Observable<Object> {
    return this.http.delete(this.baseUrl + '/' + id);
  }
}
