import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { CustomersComponent } from './customers/customers.component';
import { ArtistsComponent } from './artists/artists.component';

import { InquiriesComponent } from './inquiries/inquiries.component';
import { CompletedProjectsComponent } from './completed-projects/completed-projects.component';
import { CrudProjectComponent } from './shared/crud-project/crud-project.component';
import { CrudCustomerComponent } from './customers/crud-customer/crud-customer.component';
import { CrudArtistComponent } from './artists/crud-artist/crud-artist.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { CustomersInvoicesComponent } from './customers-invoices/customers-invoices.component';
import { ArtistsInvoicesComponent } from './artists-invoices/artists-invoices.component';

const routes: Routes = [
  { canActivate: [AuthGuard], path: '', component: HomeComponent, data: { title: '' }},
  { canActivate: [AuthGuard], path: 'inquiries', component: InquiriesComponent, data: { title: 'Inquiries' } },
  { canActivate: [AuthGuard], path: 'completed-projects', component: CompletedProjectsComponent, data: { title: 'Completed Projects' } },
  { canActivate: [AuthGuard], path: 'crud-project/:page/:id', component: CrudProjectComponent, data: { title: 'Edit project' } },
  { canActivate: [AuthGuard], path: 'crud-project/:page', component: CrudProjectComponent, data: { title: 'Create project' } },
  { canActivate: [AuthGuard], path: 'customers', component: CustomersComponent, data: { title: 'Customers' } },
  { canActivate: [AuthGuard], path: 'crud-customer/:id', component: CrudCustomerComponent, data: { title: 'Edit customer' } },
  { canActivate: [AuthGuard], path: 'crud-customer', component: CrudCustomerComponent, data: { title: 'Create customer' } },
  { canActivate: [AuthGuard], path: 'artists', component: ArtistsComponent, data: { title: 'Artists' } },
  { canActivate: [AuthGuard], path: 'crud-artist/:id', component: CrudArtistComponent, data: { title: 'Edit artist' } },
  { canActivate: [AuthGuard], path: 'crud-artist', component: CrudArtistComponent, data: { title: 'Create artist' } },
  { canActivate: [AuthGuard], path: 'customers-invoices', component: CustomersInvoicesComponent, data: { title: 'Customers Invoices' } },
  { canActivate: [AuthGuard], path: 'artists-invoices', component: ArtistsInvoicesComponent, data: { title: 'Artists Invoices' } },
  { path: 'login', component: LoginComponent},
  { canActivate: [AuthGuard], path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
