import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../shared/services/project.service';
import { Observable } from 'rxjs';
import { Project } from '../shared/models/project';

@Component({
  selector: 'app-completed-projects',
  templateUrl: './completed-projects.component.html',
  styleUrls: ['./completed-projects.component.scss']
})
export class CompletedProjectsComponent {
  displayedColumns = ['name', 'assignment', 'customer', 'customerInvoiceNumber', 'artists', 'artistsInvoiceNumbers', 'actionsColumn'];
}
