import { Component } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { ProjectArtist } from "../models/project-artist";
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ProjectService } from '../services/project.service';
import { Project } from '../models/project';
import { ProjectCustomer } from '../models/project-customer';
import { Settings } from '../models/settings';
import { GenerateInvoiceService } from '../services/generate-invoice.service';
import { ArtistElement, ArtistAssignmentElement } from './assignment-table/assignment-table.component';
import { CustomerElement, CustomerAssignmentElement } from './customer-assignment-table/customer-assignment-table.component';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'crud-project',
  templateUrl: './crud-project.component.html',
  styleUrls: ['./crud-project.component.scss']
})
export class CrudProjectComponent {
  showOverlay: boolean = false;
  title: string = '';
  id?:number;
  page?:string;
  submitted = false;
  projectForm = this.fb.group({
    name: [null, Validators.required],
    completed: [false, null],
    note:[null, null],
  });

  generatedInvoicesArtists = [];
  generatedInvoiceCustomer = [];

  customerElement:CustomerElement = {includeVat:true, customerAssignments:[]};
  artistElements:ArtistElement[] = [];

  lastArtistInvoiceNumbers:string[] = [];
  lastCustomerInvoiceNumbers:string[] = [];

  constructor(
    private fb: FormBuilder,  
    private route: ActivatedRoute, 
    private snackBar: MatSnackBar,
    private router: Router,
    private projectService: ProjectService,
    private generateInvoiceService:GenerateInvoiceService,
    public dialog: MatDialog
    ){}


  ngOnInit() {
    this.title = this.route.snapshot.data.title;

    this.id = +this.route.snapshot.paramMap.get('id'); // (+) before `params.get()` turns the string into a number
    this.page = this.route.snapshot.paramMap.get('page');

  
    if(this.id !== 0){
  
      this.projectService.getProjectById(this.id).subscribe(
        res => {
          for (let i = 0; i < res.projectArtists.length; i++) {// ToDo check if date map is neded
            res.projectArtists[i].dates = res.projectArtists[i].dates.map(d => new Date(d))
          }
          for (let i = 0; i < res.projectCustomers.length; i++) {// ToDo check if date map is neded
            res.projectCustomers[i].dates = res.projectCustomers[i].dates.map(d => new Date(d))
          }
  
          this.projectForm.patchValue({
            name: res.name,
            completed: res.completed,
            note:res.note
          });
      
          res.projectCustomers.forEach(projectCustomer =>{
            //Need to be the same for all the projectCustomer in a project
            this.customerElement.customer = projectCustomer.customer;
            this.customerElement.agencyFee = projectCustomer.agencyFee;
            this.customerElement.invoiceNumber = projectCustomer.invoiceNumber;
            this.customerElement.invoiceDate = projectCustomer.invoiceDate;
            this.customerElement.includeVat = projectCustomer.includeVat;
            let customerAssignmentElement:CustomerAssignmentElement = {
              assignment:projectCustomer.assignment,
              budget:projectCustomer.budget,
              dates: projectCustomer.dates
            }
            this.customerElement.isCredit = projectCustomer.extraSetting && projectCustomer.extraSetting.data.isCredit ? projectCustomer.extraSetting.data.isCredit : false
            this.customerElement.customerAssignments.push(customerAssignmentElement)
          })

          res.projectArtists.forEach(projectArtist =>{
            let projectArtistsFiltered:ProjectArtist[] = res.projectArtists.filter(pa => pa.artist.id === projectArtist.artist.id)
            let found_artistElement = this.artistElements.find(ae =>ae.artist.id === projectArtist.artist.id);
            if(!found_artistElement){
              let artistElement:ArtistElement ={artistAssignments:[]};
              projectArtistsFiltered.forEach(paf =>{
                //Need to be the same for all the projectArtists in a project
                artistElement.artist = paf.artist;
                artistElement.invoiceDate = paf.invoiceDate;
                artistElement.invoiceNumber = paf.invoiceNumber;
                artistElement.includeVat = paf.artist.vatNumber ? paf.includeVat : false;//TODO kijke uit
                artistElement.isCredit = paf.extraSetting && paf.extraSetting.data.isCredit ? paf.extraSetting.data.isCredit : false
                artistElement.agencyFee = paf.agencyFee;
                artistElement.artistAssignments.push({
                  assignment:paf.assignment,
                  rate:paf.rate,
                  travelCost:paf.travelCost,
                  parkingCost:paf.parkingCost,
                  additionalCost:paf.additionalCost,
                  dates:paf.dates,
                  showTravelCostCustomerInvoice: this.getExtraSetting(paf, 'showTravelCostCustomerInvoice'),
                  showParkingCostCustomerInvoice: this.getExtraSetting(paf, 'showParkingCostCustomerInvoice'),
                  showAdditionalCostCustomerInvoice: this.getExtraSetting(paf, 'showAdditionalCostCustomerInvoice'),
                  assignmentNote: this.getExtraSetting(paf, 'assignmentNote'),
                  additionalCostNote: this.getExtraSetting(paf, 'additionalCostNote'),
                  travelCostNote: this.getExtraSetting(paf, 'travelCostNote'),
                  parkingCosttNote: this.getExtraSetting(paf, 'parkingCosttNote')
                })
              });
              this.artistElements.push(artistElement)
            }
          })

          this.generateInvoiceService.getInvoices(this.id).subscribe(
            res1 => {
              if(res1.length > 0){
                this.showOverlay = true;
                this.generatedInvoicesArtists = res1.filter(invoice => invoice.artistInvoice == true);
                this.generatedInvoiceCustomer = res1.filter(invoice => invoice.customerInvoice == true);
              }
            },
            err => {this.snackBar.open("ERROR!! generateInvoices", null, {duration: 2000});}
          );
        },
        err => {this.snackBar.open("ERROR!! getProjectById", null, {duration: 2000});}
      );
    }

    this.generateInvoiceService.getLastInvoiceNumbers().subscribe(lastInvoiceNumbers =>{
      this.lastArtistInvoiceNumbers = lastInvoiceNumbers.lastArtistInvoiceNumbers;
      this.lastCustomerInvoiceNumbers = lastInvoiceNumbers.lastCustomerInvoiceNumbers;
    })
  }

  public getExtraSetting(projectArtist:ProjectArtist, name:String):any{
    const data = projectArtist.extraSetting && projectArtist.extraSetting.data ? projectArtist.extraSetting.data : null;
    if(name === 'showTravelCostCustomerInvoice'){
      return data != null && data.showTravelCostCustomerInvoice != null ? data.showTravelCostCustomerInvoice : true;
    } 
    if(name === 'showParkingCostCustomerInvoice'){
      return data != null && data.showParkingCostCustomerInvoice != null ? data.showParkingCostCustomerInvoice : true;
    } 
    if(name === 'showAdditionalCostCustomerInvoice'){
      return data != null && data.showAdditionalCostCustomerInvoice != null ? data.showAdditionalCostCustomerInvoice : true;
    } 
    if(name === 'assignmentNote'){
      return data != null && data.assignmentNote != null ? data.assignmentNote: null;
    } 
    if(name === 'additionalCostNote'){
      return data != null && data.additionalCostNote != null ? data.additionalCostNote: null;
    }
    if(name === 'travelCostNote'){
      return data != null && data.travelCostNote != null ? data.travelCostNote: null;
    }
    if(name === 'parkingCosttNote'){
      return data != null && data.parkingCosttNote != null ? data.parkingCosttNote: null;
    }
    return null
  }

  dateUtc(arr){
    for (let i = 0; i < arr.length; i++) {
      arr[i].dates = arr[i].dates.map(d => {
        let date:Date = new Date(d);
        let dateUtc:Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - date.getTimezoneOffset());
        return dateUtc;
      })
    }
  }
  formToProjectMapping(form): Project {
    let projectCustomers: ProjectCustomer[] = [];
    this.customerElement.customerAssignments.forEach(customerAssignment => {
      let projectCustomer:ProjectCustomer ={
        customer_id:this.customerElement.customer.id,

        budget: customerAssignment.budget,

        agencyFee: this.customerElement.agencyFee ? this.customerElement.agencyFee : null,
        invoiceDate: this.customerElement.invoiceDate,
        invoiceNumber: this.customerElement.invoiceNumber,
        includeVat: this.customerElement.includeVat,
        dates: customerAssignment.dates,
        assignment: customerAssignment.assignment,
        extraSetting: {
          schemaVersion: 1,
          data: {
            isCredit: this.customerElement.isCredit
          }
        }
      }
  
      projectCustomers.push(projectCustomer);
    });
    let projectArtists: ProjectArtist[] = [];
    this.artistElements.forEach(artistElement => {
      
      artistElement.artistAssignments.forEach(artistAssignment => {
        let projectArtist: ProjectArtist = {
          artist_id: artistElement.artist.id,
          
          rate: artistAssignment ? artistAssignment.rate : null,
          travelCost: artistAssignment ? artistAssignment.travelCost : null,
          parkingCost: artistAssignment ? artistAssignment.parkingCost : null,
          additionalCost: artistAssignment ? artistAssignment.additionalCost : null,
          
          agencyFee: artistElement.agencyFee ? artistElement.agencyFee : null,
          invoiceDate: this.customerElement.invoiceDate,
          invoiceNumber: artistElement.invoiceNumber,
          includeVat: artistElement.includeVat,
          dates: artistAssignment ? artistAssignment.dates : [],
          assignment: artistAssignment.assignment,
          extraSetting: {
            schemaVersion: 1,
            data: {
              isCredit: artistElement.isCredit,
              showTravelCostCustomerInvoice: artistAssignment.showTravelCostCustomerInvoice,
              showParkingCostCustomerInvoice: artistAssignment.showParkingCostCustomerInvoice,
              showAdditionalCostCustomerInvoice: artistAssignment.showAdditionalCostCustomerInvoice,
              assignmentNote: artistAssignment.assignmentNote,
              additionalCostNote: artistAssignment.additionalCostNote,
              travelCostNote: artistAssignment.travelCostNote,
              parkingCosttNote: artistAssignment.parkingCosttNote
            }
          }
        }
        projectArtists.push(projectArtist);
      });
    });

    let project:Project = {
      id: this.id,
      name: form.name,
      completed: form.completed,
      note: form.note,
      projectCustomers: projectCustomers,
      projectArtists: projectArtists,
    };
    return project;
  }



  onSubmit() {
    this.submitted = true;
    console.log("onSubmit:",this.formToProjectMapping(this.projectForm.value))
    if (this.projectForm.valid) {
      let newProject:Project = this.formToProjectMapping(this.projectForm.value);
      if(this.validMapping()){
        if(this.page === 'inquiries' && this.id === 0){
          this.projectService.createProject(newProject).subscribe(
            res => {
              this.snackBar.open("Inquiry added", null, {duration: 2000});
              this.router.navigate(['/inquiries']);
            },
            err => {this.snackBar.open("ERROR!! createProject", null, {duration: 2000});}
          );
        } else if(this.page === 'inquiries' && this.id !== 0){
          this.projectService.updateProject(this.id,newProject).subscribe(
            res => {
              this.snackBar.open("Inquiry updated", null, {duration: 2000});
              this.router.navigate(['/inquiries']);
            },
            err => {this.snackBar.open("ERROR!! updateProject", null, {duration: 2000});}
          );
        } else if(this.page === 'completed' && this.id !== 0){
          this.projectService.updateProject(this.id,newProject).subscribe(
            res => {
              this.snackBar.open("Completed project updated", null, {duration: 2000});
              this.router.navigate(['/completed-projects']);
            },
            err => {this.snackBar.open("ERROR!! completed", null, {duration: 2000});}
          );
        }
      }
    }
  }

  

  deleteProject(){
    if(this.page === 'inquiries' && this.id && this.id !== 0){
      this.projectService.deleteProject(this.id).subscribe(
        res => {
          this.snackBar.open("Delete inquiry", null, {duration: 2000});
          this.router.navigate(['/inquiries']);
        },
        err => {this.snackBar.open("ERROR!!", null, {duration: 2000});}
      );
    }else if(this.page === 'completed' && this.id && this.id !== 0){
      this.projectService.deleteProject(this.id).subscribe(
        res => {
          this.snackBar.open("Delete completed project", null, {duration: 2000});
          this.router.navigate(['/completed-projects']);
        },
        err => {this.snackBar.open("ERROR!! deleteProject", null, {duration: 2000});}
      );
    }
  }

  unlockProject(){
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '500px',
      data: {
          title: "Atention the project wil be unlocked!", 
          message: "- If you generate the project again it is possible that the customer and artist information is changed.",
          message1: "- If you deleted the project the generated invoice will still exist but can not be found any more in the project views.",
          message2: "- The data on the already generated invoices will not be changed."
      }
    }).afterClosed().subscribe(result => {
      this.showOverlay = !this.showOverlay;
    });

  }

  generateInvoices(){
    this.showOverlay = true;
    // ToDO generate invoices
    if(this.projectForm.valid){
      let newProject:Project = this.formToProjectMapping(this.projectForm.value);
      if(this.validForGenerateInvoices(newProject)){
        this.projectService.updateProject(this.id,newProject).subscribe(
          res => {
            this.generateInvoiceService.generateInvoices(this.id).subscribe(
              res1 => {
                this.generatedInvoicesArtists = res1.filter(invoice => invoice.artistInvoice == true);
                this.generatedInvoiceCustomer = res1.filter(invoice => invoice.customerInvoice == true);
              },
              err => {
                this.showOverlay = false;
                this.snackBar.open("ERROR!! generateInvoices", null, {duration: 2000});
              }
            );
          },
          err => {this.snackBar.open("ERROR!! completed", null, {duration: 2000});}
        );
      }else{
        this.showOverlay = false;
      }
    }else{
      this.showOverlay = false;
    }
  }

  downloadInvoice(id, invoiceNumber){
    this.generateInvoiceService.downloadInvoice(id, this.id,invoiceNumber);
  }


  validMapping():boolean{
    let valid:boolean = true;
    let message:string = "";


    if(!this.customerElement.customerAssignments || this.customerElement.customerAssignments.length <=0){
      message = "Customer need at least one assignment"
      valid = false;
    }
    if(this.artistElements){
      this.artistElements.forEach(artistElement => {
        if(artistElement.artistAssignments && artistElement.artistAssignments.length <=0){
          message = "Artist need a assignment"
          valid = false;
        }
      })
    }
    if(!valid){
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '500px',
        data: {
            title: "Fields are not valid!", 
            message: "",
            message1: message,
            message2: ""
        }
      });
    }
    return valid;
  }

  validForGenerateInvoices(project:Project):boolean{
    console.log("validForGenerateInvoices:",project)
    let valid:boolean = true;
    let message:string = "";
    let validMapping = this.validMapping();
    if(!validMapping){
      return validMapping;
    }else if(!project.projectCustomers[0].invoiceNumber){
      message = "Customer need a invoiceNumber"
      valid = false;
    }else if(!project.projectCustomers[0].invoiceDate){
      message = "Customer need a invoiceDate"
      valid = false;
    } else if(project.projectArtists){
      project.projectArtists.forEach(pa =>{
        if(!project.projectArtists[0].invoiceNumber){
          message = "Artist need a invoiceNumber"
          valid = false;
        }else if(!project.projectArtists[0].invoiceDate){
          message = "Artist need a invoiceDate"
          valid = false;
        }
      })
    } 
    if(!valid){
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: '500px',
        data: {
            title: "Fields are not valid!", 
            message: "",
            message1: message,
            message2: ""
        }
      });
    }
    return valid
  }

}
export function determineId(id: any): string {
  if (id.constructor.name === 'array' && id.length > 0) {
     return '' + id[0];
  }
  return '' + id;
}