import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Customer } from '../shared/models/customer';
import { CustomerService } from '../shared/services/customer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  title: string = '';
  dataSource: MatTableDataSource<Customer> = new MatTableDataSource<Customer>();
  // displayedColumns = ['name', 'phoneNumber', 'email', 'streetName', 'houseNumber', 'zipCode', 'city', 'country', 'vatNumber', 'additionalInformation1', 'additionalInformation2', 'actionsColumn'];
  displayedColumns = ['name', 'phoneNumber', 'email', 'streetName', 'houseNumber', 'zipCode', 'city', 'country', 'actionsColumn'];
  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.title = this.route.snapshot.data.title;

    this.customerService.getCustomers().subscribe(customer => {
      this.dataSource.data = customer;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
