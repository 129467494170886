import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { Customer } from 'src/app/shared/models/customer';

@Component({
  selector: 'app-crud-customer',
  templateUrl: './crud-customer.component.html',
  styleUrls: ['./crud-customer.component.scss']
})
export class CrudCustomerComponent implements OnInit {
  title: string = '';
  id?: number;
  customerForm = this.fb.group({
    name: [null, Validators.required],
  
    phoneNumber: [null, null],
    email: [null, null],

    streetName: [null, null],
    houseNumber: [null, null],
    zipCode: [null, null],
    city: [null, null],
    country: [null, null],

    vatNumber: [null, null],

    contactPersonName: [null, null],
    contactPersonEmail: [null, null],
    financialAdministrationEmail: [null, null],
    website: [null, null],

    additionalInformation1: [null, null],
    additionalInformation2: [null, null],
    tav: [null, null],
  });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private customerService: CustomerService
  ) { }


  ngOnInit() {
    this.title = this.route.snapshot.data.title;
    this.id = +this.route.snapshot.paramMap.get('id'); // (+) before `params.get()` turns the string into a number
    if (this.id !== 0) {
      this.customerService.getCustomerById(this.id).subscribe(
        res => {
          this.customerForm.patchValue({
            name: res.name,

            phoneNumber:  res.phoneNumber,
            email:  res.email,
        
            streetName:  res.streetName,
            houseNumber:  res.houseNumber,
            zipCode:  res.zipCode,
            city:  res.city,
            country:  res.country,

            vatNumber:res.vatNumber,

            contactPersonName: res.contactPersonName,
            contactPersonEmail: res.contactPersonEmail,
            financialAdministrationEmail: res.financialAdministrationEmail,
            website: res.website,

            additionalInformation1: res.additionalInformation1,
            additionalInformation2: res.additionalInformation2,
            tav: res.tav
          });
        },
        err => { this.snackBar.open("ERROR!! getCustomerById", null, { duration: 2000 }); }
      );
    }
  }

  onSubmit() {
    if (this.customerForm.valid) {
      let newCustomer: Customer = this.customerForm.value;
      if (this.id === 0) {
        this.customerService.createCustomer(newCustomer).subscribe(
          res => {
            this.snackBar.open("Customer added", null, { duration: 2000 });
            this.router.navigate(['/customers']);
          },
          err => { this.snackBar.open("ERROR!! createCustomer", null, { duration: 2000 }); }
        );
      } else if (this.id !== 0) {
        this.customerService.updateCustomer(this.id, newCustomer).subscribe(
          res => {
            this.snackBar.open("Customer updated", null, { duration: 2000 });
            this.router.navigate(['/customers']);
          },
          err => { this.snackBar.open("ERROR!! updateCustomer", null, { duration: 2000 }); }
        );
      }
    }
  }

  deleteCustomer() {
    if (this.id && this.id !== 0) {
      this.customerService.deleteCustomer(this.id).subscribe(
        res => {
          this.snackBar.open("Customer delete", null, { duration: 2000 });
          this.router.navigate(['/customers']);
        },
        err => { this.snackBar.open("ERROR!! deleteCustomer", null, { duration: 2000 }); }
      );
    }
  }
}

