import { Component } from '@angular/core';

@Component({
  selector: 'app-inquiries',
  templateUrl: './inquiries.component.html',
  styleUrls: ['./inquiries.component.scss']
})
export class InquiriesComponent {
  displayedColumns = ['name','assignment','customer','artists','note','actionsColumn'];
}
