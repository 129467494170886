import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

   // downloads a file; based on "showFile" function of blog post: https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
   downloadFile(file: File): void {
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(file, file.name); // needed to support IE11
    } else {
      const fileURL = URL.createObjectURL(file);

      try { // create a link tag including "download" property so that file name while downloading
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
      } finally {
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(fileURL);
        }, 100);
      }
    }
  }
}


// showFile(blob){
//   // It is necessary to create a new blob object with mime-type explicitly set
//   // otherwise only Chrome works like it should
//   var newBlob = new Blob([blob], {type: "application/pdf"})

//   // IE doesn't allow using a blob object directly as link href
//   // instead it is necessary to use msSaveOrOpenBlob
//   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//     window.navigator.msSaveOrOpenBlob(newBlob);
//     return;
//   } 

//   // For other browsers: 
//   // Create a link pointing to the ObjectURL containing the blob.
//   const data = window.URL.createObjectURL(newBlob);
//   var link = document.createElement('a');
//   link.href = data;
//   link.download="file.pdf";
//   link.click();
//   setTimeout(function(){
//     // For Firefox it is necessary to delay revoking the ObjectURL
//     window.URL.revokeObjectURL(data);
//   , 100}
// }

// fetch([url to fetch], {[options setting custom http-headers]})
// .then(r => r.blob())
// .then(showFile)