import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { Customer } from '../models/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  baseUrl: string = '/api/customers';

  constructor(private http: HttpClient) { }

  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.baseUrl);
  }

  getCustomerById(id: number): Observable<Customer> {
    return this.http.get<Customer>(this.baseUrl + '/' + id);
  }

  createCustomer(customer: Customer): Observable<Object> {
    return this.http.post(this.baseUrl, customer);
  }

  updateCustomer(id: number, customer: Customer): Observable<Object> {
    return this.http.put(this.baseUrl + '/' + id, customer);
  }

  deleteCustomer(id: number): Observable<Object> {
    return this.http.delete(this.baseUrl + '/' + id);
  }
}
