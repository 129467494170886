import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../models/project';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  baseUrl: string = '/api/projects';

  constructor(private http: HttpClient) { }

  getProjects(completed:boolean,invoicesGenerated:boolean = null): Observable<Project[]> {
    if(completed){
      return this.http.get<Project[]>(this.baseUrl + '?completed=true'+ '&invoicesGenerated='+ invoicesGenerated);
    }else{
      return this.http.get<Project[]>(this.baseUrl + '?completed=false');
    }
  }

  getProjectById(id: number): Observable<Project> {
    return this.http.get<Project>(this.baseUrl + '/' + id);
  }

  createProject(project: Project): Observable<Object> {
    return this.http.post(this.baseUrl, project);
  }

  updateProject(id: number, project: Project): Observable<Object> {
    return this.http.put(this.baseUrl + '/' + id, project);
  }

  deleteProject(id: number): Observable<Object> {
    return this.http.delete(this.baseUrl + '/' + id);
  }
}