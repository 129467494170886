import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Project } from '../models/project';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Project> = new MatTableDataSource<Project>();

  @Input() displayedColumns = ['name', 'assignment', 'customer', 'customerInvoiceNumber', 'artists', 'artistsInvoiceNumbers', 'note', 'actionsColumn'];
  @Input() page: string;
  @Input() id: number;
  projectsObservable: Observable<Project[]>;
  title: string = '';
  viewOnlyGeneratedInvoices="all";

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.title = this.route.snapshot.data.title;
    this.projectsObservable = this.projectService.getProjects(this.page === 'completed',this.getViewOnlyGeneratedInvoices());

    this.projectsObservable.subscribe(projects => {
      this.dataSource.data = projects;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
    });
  }
  
  viewOnlyGeneratedInvoicesChanged(){
    this.projectsObservable = this.projectService.getProjects(this.page === 'completed',this.getViewOnlyGeneratedInvoices());
    this.projectsObservable.subscribe(projects => {
      this.dataSource.data = projects;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
    });
  }

  getViewOnlyGeneratedInvoices(){
    switch (this.viewOnlyGeneratedInvoices){
      case "onlyGenerated":
        return true;
      case "onlyNotGenerated":
        return false;
      default:
        return null;
    }
  }

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
         case 'name': {
          return item.name.toLowerCase();
        }
        case 'assignment': {
          return item.projectCustomers[0] && item.projectCustomers[0].assignment ?item.projectCustomers[0].assignment.toLowerCase():null;
        }
        case 'customer': {
          return item.projectCustomers[0] && item.projectCustomers[0].customer ?item.projectCustomers[0].customer.name.toLowerCase():null;
        }
        case 'customerInvoiceNumber': {
          return item.projectCustomers[0] && item.projectCustomers[0].invoiceNumber ?item.projectCustomers[0].invoiceNumber.toLowerCase():null;
        }
        case 'artists': {
          return item.projectArtists[0] && item.projectArtists[0].artist ?item.projectArtists[0].artist.firstName.toLowerCase():null;
        }
        case 'artistsInvoiceNumbers': {
          return item.projectArtists[0] && item.projectArtists[0].invoiceNumber ?item.projectArtists[0].invoiceNumber.toLowerCase():null;
        }
        case 'note': {
          return item.note ? item.note.toLowerCase():null;
        }
        default: {
          return item[property];
        }
      }
    };
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let name = data.name ? data.name.toLowerCase().indexOf(filter) !== -1 : false;
      let found = false;
      if(data.projectCustomers){
        data.projectCustomers.forEach(pc => {
          if(!found && pc.name){
            found = pc.name.toLowerCase().indexOf(filter) !== -1;
          }
          if(!found && pc.assignment){
            found = pc.assignment.toLowerCase().indexOf(filter) !== -1;
          }
          if(!found && pc.customer && pc.customer.name){
            found = pc.customer.name.toLowerCase().indexOf(filter) !== -1;
          }
          if(!found && pc.budget){
            found = pc.budget.toString().toLowerCase().indexOf(filter) !== -1;
          }
          if(!found && pc.invoiceNumber){
            found = pc.invoiceNumber.toLowerCase().indexOf(filter) !== -1;
          }
          if(!found && pc.dates){
            pc.dates.forEach(d => {
              found = formatDate(d, 'dd-MM-y', 'en-US').toString().toLowerCase().indexOf(filter) !== -1;
            });
          }
        });
      }
      if(data.projectArtists){
        data.projectArtists.forEach(pa => {
          if(!found && pa.artist){
            let name:string = pa.artist.firstName + " " + pa.artist.lastName;
            found = name.toLowerCase().indexOf(filter) !== -1;
          }
          if(!found && pa.invoiceNumber){
            found = pa.invoiceNumber.toLowerCase().indexOf(filter) !== -1;
          }
        });
      }

      let note = data.note ? data.note.toLowerCase().indexOf(filter) !== -1 : false;
      return name || found || note;
    }
    return filterFunction;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
