import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Artist } from '../../models/artist';
import { CustomerElementService } from '../customer-assignment-table/customer-assignment-table.service';
import { FormControl } from '@angular/forms';
import { CustomerElement } from '../customer-assignment-table/customer-assignment-table.component';


@Component({
  selector: 'assignment-table',
  templateUrl: './assignment-table.component.html',
  styleUrls: ['./assignment-table.component.scss']
})
export class AssignmentTableComponent implements OnInit {
  customerElement:CustomerElement;
  @Input() page:string;
  displayedColumns: string[] = [];
  
  assignmentOptions: string[] = [];
  assignmentOptionsClone: string[] = [];
  assignmentsFormControl = new FormControl();

  @Input() lastArtistInvoiceNumbers: string[] = [];
  
  @Input() artistElement:ArtistElement= {};
  // @Output() onChange = new EventEmitter<ArtistElement>();
  @Output() onDelete = new EventEmitter<ArtistElement>();
  dataSource = [];
  constructor(
    private customerElementService:CustomerElementService
  ) {}

  ngOnInit() {
    this.customerElementService.dateStore$.subscribe(customerElement =>{
      this.artistElement.invoiceDate = customerElement.invoiceDate;
      this.assignmentOptions = customerElement.customerAssignments.map(customerAssignment => customerAssignment.assignment);
      this.assignmentOptionsClone = Object.assign([], this.assignmentOptions);
      this.customerElement = customerElement;
    })

    this.dataSource = this.artistElement.artistAssignments;

    if(this.page === 'completed'){
      // ['assignment', 'rate', 'travelCost', 'additionalCost','dates', 'actionsColumn']
      this.displayedColumns = ['assignment', 'rate', 'travelCost', 'parkingCost', 'additionalCost','dates', 'actionsColumn'];
    }else{
      this.displayedColumns = ['assignment', 'dates', 'actionsColumn'];
    }
  }

  inputOnChange(assignment: string, property: string, event) {  
    if (property === 'rate') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment);
      this.dataSource[itemIndex].rate = Number(event.target.value);
    } else if (property === 'travelCost') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment);
      this.dataSource[itemIndex].travelCost = Number(event.target.value);
    } else if (property === 'parkingCost') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment);
      this.dataSource[itemIndex].parkingCost = Number(event.target.value);
    } else if (property === 'additionalCost') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment);
      this.dataSource[itemIndex].additionalCost = Number(event.target.value);
    } else if (property === 'agencyFee') {
      this.artistElement.agencyFee = Number(event.target.value);
    } else if (property === 'invoiceNumber') {
      this.artistElement.invoiceNumber = event.target.value;
    } else if (property === 'invoiceNumberSelect') {
      this.artistElement.invoiceNumber = event.option.value;
    } else if (property === 'vat') {
      this.artistElement.includeVat = event;
    }else if (property === 'dates') {  
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].dates = event.dates;
    } else if (property === 'isCredit') {
      this.artistElement.isCredit = event;
    } else if (property === 'showTravelCostCustomerInvoice') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].showTravelCostCustomerInvoice = event;
    } else if (property === 'showParkingCostCustomerInvoice') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].showParkingCostCustomerInvoice = event;
    } else if (property === 'showAdditionalCostCustomerInvoice') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].showAdditionalCostCustomerInvoice = event;
    }else if (property === 'assignmentNote') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].assignmentNote = event.target.value;
    }else if (property === 'additionalCostNote') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].additionalCostNote = event.target.value;
    }else if (property === 'travelCostNote') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].travelCostNote = event.target.value;
    }else if (property === 'parkingCosttNote') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].parkingCosttNote = event.target.value;
    }
    this.artistElement.artistAssignments = this.dataSource;
    // this.onChange.emit(this.artistElement);
  }

  deleteAssignment(assignment: string) {
    // let found_assignment = this.dataSource.find(item => item.assignment === assignment).assignment;

    // this.dataSource = this.dataSource.filter(function (obj) {
    //   return obj.assignment !== found_assignment;
    // });;

    let dataSourceClone = Object.assign([], this.dataSource);
    let itemIndex = dataSourceClone.findIndex(item => item.assignment === assignment);
    dataSourceClone.splice(itemIndex, 1);
    this.dataSource = dataSourceClone;
    this.artistElement.artistAssignments = this.dataSource;
    // this.onChange.emit(this.artistElement);
  }

  deleteArtist() {
    this.onDelete.emit(this.artistElement);
  }
  addAssignment(){
    if (this.assignmentsFormControl.value) {
      let dataSourceClone = Object.assign([], this.dataSource);
      this.assignmentsFormControl.value.forEach(assignment => {
        dataSourceClone.push({
          assignment:assignment,
          dates:[],
          showTravelCostCustomerInvoice: true,
          showParkingCostCustomerInvoice: true,
          showAdditionalCostCustomerInvoice: true
        })
      });
      this.dataSource = dataSourceClone;
      this.artistElement.artistAssignments = this.dataSource;
    }

    this.assignmentsFormControl.patchValue([]);
  }

  getAssignmentOptions(){
    this.assignmentOptions = Object.assign([], this.assignmentOptionsClone);
    this.artistElement.artistAssignments.forEach(artistAssignment => {
      this.assignmentOptions = this.assignmentOptions.filter(function (assignmentOption) {
        return assignmentOption !== artistAssignment.assignment;
      });
    })

    return this.assignmentOptions;
  }
}
export interface ArtistElement {
  artist?: Artist
  agencyFee?: number;
  invoiceDate?: Date;
  invoiceNumber?: string;
  includeVat?: boolean;
  artistAssignments?:ArtistAssignmentElement[];
  isCredit?: boolean;
}
export interface ArtistAssignmentElement {
  assignment?:string;
  rate?: number;
  travelCost?: number;
  parkingCost?: number;
  additionalCost?: number;
  dates?: Date[];
  showTravelCostCustomerInvoice?: boolean;
  showParkingCostCustomerInvoice?: boolean;
  showAdditionalCostCustomerInvoice?: boolean;
  assignmentNote?: string;
  additionalCostNote?: string;
  travelCostNote?: string;
  parkingCosttNote?: string;
}

