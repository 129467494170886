import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { MatDialog } from '@angular/material';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,public dialog: MatDialog) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }else{
                let msg = err.error ? err.error.split("<p>"): null;
                const dialogRef = this.dialog.open(MessageDialogComponent, {
                    width: '500px',
                    data: {
                        title: "Error", 
                        message: err.status,
                        message1: err.statusText,
                        message2: msg[1] ? msg[1].split("</p>")[0]: ""
                    }
                });
                console.error("dialogRef error:",err);
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}