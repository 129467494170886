import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProjectArtist } from '../../models/project-artist';
import { FormControl } from '@angular/forms';
import { Artist } from '../../models/artist';
import { ArtistService } from '../../services/artist.service';
import { MatSnackBar } from '@angular/material';
import { ArtistElement } from '../assignment-table/assignment-table.component';

@Component({
  selector: 'artist-list',
  templateUrl: './artist-list.component.html',
  styleUrls: ['./artist-list.component.scss']
})
export class ArtistListComponent implements OnInit {
  @Input() page:string;
  @Input() artistElements:ArtistElement[] =[]

  searchArtistFormControl = new FormControl();
  artistOptions: Artist[];
  artistOptionsClone: Artist[];

  @Input() lastArtistInvoiceNumbers: string[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private artistService: ArtistService
    ) { }

  ngOnInit() {
    this.artistService.getArtists().subscribe(
      res => {
        this.artistOptions = res;
        this.artistOptionsClone = Object.assign([], this.artistOptions);
      },
      err => {this.snackBar.open("ERROR!! getArtists", null, {duration: 2000});}
    );
  }
  getArtistOptions(){
    this.artistOptions = Object.assign([], this.artistOptionsClone);
    this.artistElements.forEach(artistElement => {
      this.artistOptions = this.artistOptions.filter(function (artist) {
          return artist.id !== artistElement.artist.id;
      });
    })

    return this.artistOptions;
  }
  addArtist(): void {
    // const dataSourceClone  = Object.assign([], this.dataSource);
    if (this.searchArtistFormControl.value) {
      this.searchArtistFormControl.value.forEach(artist => {
        let newArtistElement:ArtistElement ={
          artist:artist,
          artistAssignments:[],
          invoiceDate:null,
          invoiceNumber:null,
          includeVat: artist.vatNumber ? true : false,
          agencyFee:null
        }
        this.artistElements.push(newArtistElement)
      });
    }
    // this.dataSource = dataSourceClone;
    // this.dataSourceChange.emit(this.dataSource)
    this.searchArtistFormControl.patchValue([]);
  }
  deleteArtist(artistElement: ArtistElement) {
   
    let itemIndex = this.artistElements.findIndex(item => item.artist.id == artistElement.artist.id);
    this.artistElements.splice(itemIndex, 1);
    // this.dataSourceChange.emit(this.dataSource)
  }
  // artistChange(artistElement: ArtistElement) {
  //   const artistElementsClone  = Object.assign([], this.artistElements);
  //   let itemIndex = artistElementsClone.findIndex(item => item.artist.id== artistElement.artist.id);
  //   artistElementsClone[itemIndex] = artistElement;
  //   // this.onArtistElements.emit(artistElementsClone);
  // }

}
