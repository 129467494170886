import { Component, OnInit, ViewChild, EventEmitter, Input, Output, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { DataListService } from './data-list.service';
import { CustomerElementService } from '../customer-assignment-table/customer-assignment-table.service';

@Component({
  selector: 'date-list',
  templateUrl: './date-list.component.html',
  styleUrls: ['./date-list.component.scss']
})
export class DateListComponent implements OnInit {
  @ViewChild(MatMenuTrigger) triggerr: MatMenuTrigger;

  @Input() dates: Date[] = [];
  @Input() showDatepicker: boolean = false;
  @Output() datesChange = new EventEmitter<Date[]>();
  datesMenu: Date[] = [];
  @Input() editMode: boolean = true;
  @Input() error: boolean = false;

  @Input() assignment:string = "";

  constructor(
    // private dataListService:DataListService
    private customerElementService:CustomerElementService
  ) { }
  getDatesMenu(){
    return this.datesMenu;
  }
  ngOnInit() {
    // this.dataListService.dateStore$.subscribe(dates =>{
    //   this.datesMenu = dates;
    //   this.dates = this.dates.filter(d => dates.find(df => df.getTime() === d.getTime()));
    // })
    this.customerElementService.dateStore$.subscribe(customerElement =>{
      let found_customerAssignment = customerElement.customerAssignments.find(customerAssignment => customerAssignment.assignment === this.assignment);
      if(found_customerAssignment){
        this.datesMenu = found_customerAssignment.dates;
      }
    })
  }

  addDate(datein) {
    let date:Date = new Date(datein);
    if (!this.datesContains(date)) {
      let dateUtc:Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - date.getTimezoneOffset());
      this.dates.push(dateUtc);
      this.changeEmit();
    }
    // this.triggerr.closeMenu();
  }

  removeDate(datein) {
    let date:Date = new Date(datein);
    if (this.datesContains(date)) {
      this.dates = this.dates.filter(d => d.getTime() !== date.getTime());
      this.changeEmit();
    }
  }

  datesContains(datein: Date): boolean {
    let date:Date = new Date(datein);
    return this.dates.find(d => d.toLocaleDateString() === date.toLocaleDateString()) ? true : false;
  }

  changeEmit() {
    this.sortByDueDate();
    this.datesChange.emit(this.dates);
  }

  sortByDueDate(): void {
    this.dates.sort((a: Date, b: Date) => {
      return this.getTime(a) - this.getTime(b);
    });
  }
  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }


}