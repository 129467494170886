import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Customer } from '../../models/customer';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerElementService } from './customer-assignment-table.service';
import { MatSnackBar } from '@angular/material';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'customer-assignment-table',
  templateUrl: './customer-assignment-table.component.html',
  styleUrls: ['./customer-assignment-table.component.scss']
})
export class CustomerAssignmentTableComponent implements OnInit {
  customerOptions: Customer[] = [];
  assignmentInput:string="";

  @Input() page:string;
  displayedColumns: string[] = [];
  
  @Input() customerElement:CustomerElement= {};
  @Output() onDelete = new EventEmitter<CustomerElement>();
  dataSource = [];

  @Input() lastCustomerInvoiceNumbers: string[] = [];
  constructor(
    private customerElementService:CustomerElementService,
    private snackBar: MatSnackBar,
    private customerService: CustomerService,
    ) { }

  ngOnInit() {
    this.customerService.getCustomers().subscribe(
      res => {
        this.customerOptions = res;
      },
      err => {this.snackBar.open("ERROR!! getProjectCustomers", null, {duration: 2000});}
    );
    this.dataSource = this.customerElement.customerAssignments;
    this.displayedColumns = ['assignment', 'budget', 'dates','actionsColumn'];
    this.customerElementService.update(this.customerElement);
  }

  inputOnChange(assignment: string, property: string, event) {  
    if (property === 'budget') {
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment);
      this.dataSource[itemIndex].budget = Number(event.target.value);
    }else if (property === 'dates') {  
      let itemIndex = this.dataSource.findIndex(item => item.assignment === assignment); 
      this.dataSource[itemIndex].dates = event.dates;
    }else if (property === 'agencyFee') {
      this.customerElement.agencyFee = Number(event.target.value);
    } else if (property === 'invoiceNumber') {
      this.customerElement.invoiceNumber = event.target.value;
    } else if (property === 'invoiceNumberSelect') {
      this.customerElement.invoiceNumber = event.option.value;
    }  else if (property === 'vat') {
      this.customerElement.includeVat = event;
    } else if (property === 'invoiceDate') {
      let date:Date = new Date(event.target.value);
      let dateUtc:Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - date.getTimezoneOffset());
      this.customerElement.invoiceDate = dateUtc
    } else if (property === 'customer') {
      this.customerElement.customer = event.value
    }else if (property === 'isCredit') {
      this.customerElement.isCredit = event;
    }
    this.customerElement.customerAssignments = this.dataSource;
    this.customerElementService.update(this.customerElement);
  }

  addAssignment(){
    this.assignmentInput = this.assignmentInput.trim();
    let found_assignment = this.dataSource.find(item => item.assignment === this.assignmentInput);
    if(this.assignmentInput && this.assignmentInput.length !== 0 && !found_assignment){
      const dataSourceClone  = Object.assign([], this.dataSource);
      dataSourceClone.push({assignment:this.assignmentInput,dates:[]})
      this.dataSource = dataSourceClone;
      this.customerElement.customerAssignments = this.dataSource;
      this.customerElementService.update(this.customerElement);
      this.assignmentInput = "";
    }
  }

  deleteAssignment(assignment: string) {
    let found_assignment = this.dataSource.find(item => item.assignment === assignment).assignment;

    this.dataSource = this.dataSource.filter(function (obj) {
      return obj.assignment !== found_assignment;
    });;
    this.customerElement.customerAssignments = this.dataSource;
    this.customerElementService.update(this.customerElement);
  }
  
  selectedCustomercompare(o1: Customer, o2: Customer): boolean {
    return o1.id === o2.id;
  }
}

export interface CustomerElement {
  customer?: Customer;
  agencyFee?: number;
  invoiceDate?: Date;
  invoiceNumber?: string;
  includeVat?: boolean;
  customerAssignments?:CustomerAssignmentElement[];
  isCredit?: boolean;
}
export interface CustomerAssignmentElement {
  assignment?:string;
  budget?: number;
  dates?: Date[];
}
