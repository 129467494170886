import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { GenerateInvoiceService } from '../shared/services/generate-invoice.service';
import { Invoice } from '../shared/models/invoice';
import { formatDate } from '@angular/common';
import { Project } from '../shared/models/project';

@Component({
  selector: 'app-artists-invoices',
  templateUrl: './artists-invoices.component.html',
  styleUrls: ['./artists-invoices.component.scss']
})
export class ArtistsInvoicesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  title: string = '';
  dataSource: MatTableDataSource<Invoice> = new MatTableDataSource<Invoice>();

  displayedColumns = ['invoiceNumber','invoiceDate', 'actionsColumn'];

  constructor(
    private generateInvoiceService: GenerateInvoiceService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.title = this.route.snapshot.data.title;
    this.generateInvoiceService.getAllInvoices(true,false).subscribe(invoices => {
      this.dataSource.data = invoices;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
    });

  }

    /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
         case 'invoiceNumber': {
          return item.displayname.toLowerCase();
        }
        case 'invoiceDate': {
          return formatDate(item.invoiceDate, 'dd-MM-y', 'en-US').toString().toLowerCase();
        }
        default: {
          return item[property];
        }
      }
    };
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let invoiceNumber = data.displayname ? data.displayname.toLowerCase().indexOf(filter) !== -1 : false;
      let invoiceDate = formatDate(data.invoiceDate, 'dd-MM-y', 'en-US').toString().toLowerCase().indexOf(filter) !== -1;
      return invoiceNumber || invoiceDate;
    }
    return filterFunction;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  downloadInvoice(id, projectId,invoiceNumber){
    this.generateInvoiceService.downloadInvoice(id, projectId,invoiceNumber);
  }
}
