import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Invoice, LastInvoiceNumbers } from '../models/invoice';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { FileDownloadService } from './file-download.service';

@Injectable({
  providedIn: 'root'
})
export class GenerateInvoiceService {
  baseUrl: string = '/api/';

  constructor(
    private http: HttpClient,
    private fileDownloadService:FileDownloadService
    ) { }


  generateInvoices(projectId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.baseUrl + 'generate-invoices' + '/' + projectId);
  }

  getAllInvoices(artistInvoice: boolean, customerInvoice: boolean): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.baseUrl + 'invoices' + '?artistInvoice=' + artistInvoice+'&customerInvoice=' + customerInvoice);
  }

  getInvoices(projectId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.baseUrl + 'invoices' + '/' + projectId);
  }

  downloadInvoice(id: number, projectId: number, invoiceNumber: number) {
    let url = this.baseUrl + 'download-invoice'+ '/' + id + '/' + projectId + '/'+ invoiceNumber+ '/true';
    // window.location.href = url;
    this.http.get(url, {observe: 'response', responseType: 'blob'}).pipe(
      map((resp) => {
        var filename = resp.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
        var blob = resp.body;
        return new File([blob], filename, {type: "application/pdf"});
      })
    ).subscribe((file) => {
      this.fileDownloadService.downloadFile(file);
    });
  }

  getLastInvoiceNumbers():Observable<LastInvoiceNumbers>{
    let url = this.baseUrl + 'last-invoice-numbers';
    return this.http.get<LastInvoiceNumbers>(url);
  }
}
