import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerElement } from './customer-assignment-table.component';

@Injectable({
  providedIn: 'root'
})
export class CustomerElementService {
  private dateStore = new BehaviorSubject({});
  dateStore$:Observable<CustomerElement> = this.dateStore.asObservable();

  constructor() { }

  update(customerElement:CustomerElement){
    this.dateStore.next(customerElement);
  }
}
