import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ArtistService } from 'src/app/shared/services/artist.service';
import { Artist } from 'src/app/shared/models/artist';

@Component({
  selector: 'app-crud-artist',
  templateUrl: './crud-artist.component.html',
  styleUrls: ['./crud-artist.component.scss']
})
export class CrudArtistComponent implements OnInit {
  title: string = '';
  id?: number;
  artistForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
  
    phoneNumber: [null, null],
    email: [null, null],

    streetName: [null, null],
    houseNumber: [null, null],
    zipCode: [null, null],
    city: [null, null],
    country: [null, null],

    vatNumber: [null, null],

    kor: [null, null],

    kvkNumber: [null, null],
    ibanNumber: [null, null],
    bankAccountHolder: [null, null],
    passportNumber: [null, null],

    additionalInformation1: [null, null],
    additionalInformation2: [null, null],
  });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private artistService: ArtistService
  ) { }


  ngOnInit() {
    this.title = this.route.snapshot.data.title;
    this.id = +this.route.snapshot.paramMap.get('id'); // (+) before `params.get()` turns the string into a number
    if (this.id !== 0) {
      this.artistService.getArtistById(this.id).subscribe(
        res => {
          this.artistForm.patchValue({
            firstName: res.firstName,
            lastName: res.lastName,

            phoneNumber:  res.phoneNumber,
            email:  res.email,
        
            streetName:  res.streetName,
            houseNumber:  res.houseNumber,
            zipCode:  res.zipCode,
            city:  res.city,
            country:  res.country,

            vatNumber:res.vatNumber,

            kor:res.kor,

            kvkNumber: res.kvkNumber,
            ibanNumber: res.ibanNumber,
            bankAccountHolder: res.bankAccountHolder,
            passportNumber: res.passportNumber,

            additionalInformation1: res.additionalInformation1,
            additionalInformation2: res.additionalInformation2
          });
        },
        err => { this.snackBar.open("ERROR!! getArtistById", null, { duration: 2000 }); }
      );
    }
  }

  onSubmit() {
    if (this.artistForm.valid) {
      let newArtist: Artist = this.artistForm.value;
      if (this.id === 0) {
        this.artistService.createArtist(newArtist).subscribe(
          res => {
            this.snackBar.open("Artist added", null, { duration: 2000 });
            this.router.navigate(['/artists']);
          },
          err => { this.snackBar.open("ERROR!! createArtist", null, { duration: 2000 }); }
        );
      } else if (this.id !== 0) {
        this.artistService.updateArtist(this.id, newArtist).subscribe(
          res => {
            this.snackBar.open("Artist updated", null, { duration: 2000 });
            this.router.navigate(['/artists']);
          },
          err => { this.snackBar.open("ERROR!! updateArtist", null, { duration: 2000 }); }
        );
      }
    }
  }

  deleteArtist() {
    if (this.id && this.id !== 0) {
      this.artistService.deleteArtist(this.id).subscribe(
        res => {
          this.snackBar.open("Artist delete", null, { duration: 2000 });
          this.router.navigate(['/artists']);
        },
        err => { this.snackBar.open("ERROR!! deleteArtist", null, { duration: 2000 }); }
      );
    }
  }

}
