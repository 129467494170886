import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Artist } from '../shared/models/artist';
import { ArtistService } from '../shared/services/artist.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  title: string = '';
  dataSource: MatTableDataSource<Artist> = new MatTableDataSource<Artist>();
  // displayedColumns = ['firstName', 'lastName', 'phoneNumber', 'email', 'streetName', 'houseNumber', 'zipCode', 'city', 'country', 'vatNumber','additionalInformation1', 'additionalInformation2', 'actionsColumn'];
  displayedColumns = ['firstName', 'lastName', 'phoneNumber', 'email', 'streetName', 'houseNumber', 'zipCode', 'city', 'country', 'actionsColumn'];

  constructor(
    private artistService: ArtistService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.title = this.route.snapshot.data.title;

    this.artistService.getArtists().subscribe(artist => {
      this.dataSource.data = artist;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
